<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="$emit('close')"
  >
    <template slot="title">
      {{ title }}
    </template>
    <template slot="body">
      <ValidationObserver ref="form">
        <div class="md-layout">
          <div class="md-layout-item">
            <form-text
              v-model="model.name"
              name="name"
              rules="required"
              icon="label"
              label="Name"
              :disabled="!editing"
              :max-chars="30"
            />
          </div>
          <div class="md-layout-item">
            <form-text
              v-model="model.slug_name"
              name="slug_name"
              rules="required"
              icon="label_important"
              label="Slug Name"
              :disabled="!editing"
              :max-chars="15"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <form-text
              v-model="model.description"
              name="description"
              rules="required"
              icon="list"
              label="Description"
              :disabled="!editing"
              :max-chars="120"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <h3>Special Permissions</h3>
            <div class="widgets">
              <md-switch
                v-for="item of permissions"
                :key="item.permission_id+'_perm'"
                v-model="model.permissions"
                :value="item.permission_id"
                :disabled="!editing"
              >
                {{ item.name }}
              </md-switch>
            </div>
          </div>
          <div class="md-layout-item md-size-100">
            <h3>Default Widgets</h3>
            <div class="widgets">
              <md-switch
                v-for="item of widgets"
                :key="item.widget_id+'_widget'"
                v-model="model.widgets"
                :value="item.widget_id"
                :disabled="!editing"
                @change="onChangeWidget(item, $event)"
              >
                {{ item.name }}
              </md-switch>
            </div>
          </div>
          <div class="md-layout-item md-size-100">
            <h3>Endpoints</h3>
            <small>
              Select
              <a
                href="#"
                @click.prevent="selectEP('all')"
              >All</a> /
              <a
                href="#"
                @click.prevent="selectEP('lists')"
              >LISTS</a> /
              <a
                href="#"
                @click.prevent="selectEP('updaters')"
              >CREATE, UPDATE and DELETE</a> /
              <a
                href="#"
                @click.prevent="selectEP('none')"
              >None</a> /
            </small>

            <div class="endpoints">
              <md-switch
                v-for="item of endpoints"
                :key="item.endpoint_id+'_endp'"
                v-model="model.endpoints"
                :value="item.endpoint_id"
                :disabled="!editing"
              >
                {{ item.name }} ({{ item.description }})
              </md-switch>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </template>
    <template slot="footer">
      <md-button
        v-if="!editing"
        class="md-warning"
        @click="editing = true"
      >
        <md-icon class="mr-2">
          edit
        </md-icon>
        Edit Role
      </md-button>

      <md-button
        v-if="editing"
        class="md-success"
        @click="saveRole"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { FormText } from '@/components/Inputs';

extend('required', required);

export default {
  components: {
    FormText,
  },
  props: {
    role: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      model: {
        name: null,
        slug_name: null,
        description: null,
        endpoints: [],
        widgets: [],
        permissions: [],
      },
      title: 'Role form',
      editing: true,
      endpoints: [],
      widgets: [],
      permissions: [],
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    Promise.all([
      this.getRole(),
      this.getEndpoints(),
      this.getWidgets(),
      this.getPermissions(),
    ]).then(() => {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    });
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    selectEP(type) {
      let endpoints = [];

      switch (type) {
        case 'all': endpoints = this.endpoints.slice(); break;
        case 'lists': endpoints = this.endpoints.filter((x) => x.method === 'GET'); break;
        case 'updaters': endpoints = this.endpoints.filter((x) => x.method !== 'GET'); break;
        case 'none': endpoints = []; break;
        default: break;
      }

      this.model.endpoints = endpoints.map((x) => x.endpoint_id);
    },
    getWidgets() {
      return new Promise((resolve) => {
        this.request(this.$API.WIDGETS, 'get', null, ({ data }) => {
          this.widgets = data;
          resolve();
        });
      });
    },
    getEndpoints() {
      return new Promise((resolve) => {
        this.request(this.$API.ENDPOINTS, 'get', null, ({ data }) => {
          this.endpoints = data;
          resolve();
        });
      });
    },
    getPermissions() {
      return new Promise((resolve) => {
        this.request('permissions', 'get', null, ({ data }) => {
          this.permissions = data;
          resolve();
        });
      });
    },
    saveRole() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          if (this.model.role_id) {
            this.request(this.$API.ROLES + this.model.role_id, 'put', this.model, () => {
              this.fireSuccess('Role updated succesfully!');
              this.$emit('onSave');
              this.close();
            });
          } else {
            this.request(this.$API.ROLES, 'post', this.model, () => {
              this.fireSuccess('Role created succesfully!');
              this.$emit('onSave');
              this.close();
            });
          }
        }
      });
    },
    getRole() {
      return new Promise((resolve) => {
        if (this.role) {
          this.title = 'Role details';
          this.model = {
            ...this.role,
            endpoints: [],
            widgets: [],
            permissions: [],
          };
          this.editing = false;

          this.request(`roles/${this.role.role_id}`, 'get', null, ({ data }) => {
            this.model.endpoints = data.endpoints.map((x) => x.endpoint_id);
            this.model.widgets = data.widgets.map((x) => x.widget_id);
            this.model.permissions = data.permissions.map((x) => x.permission_id);
            resolve();
          });
        } else {
          resolve();
        }
      });
    },
    onChangeWidget(widget, val) {
      if (this.loading) return;

      const added = val.includes(widget.widget_id);
      const endpoint_ids = widget.widget_endpoints.map((x) => x.endpoint_id);

      const ep_set = new Set(this.model.endpoints);

      for (const ep_id of endpoint_ids) {
        if (added) {
          ep_set.add(ep_id);
        } else {
          ep_set.delete(ep_id);
        }
      }

      this.model.endpoints = [...ep_set];
    },
  },
};
</script>

<style scoped>
.endpoints {
  max-height: 300px;
  overflow: auto;
}
.widgets {
  max-height: 200px;
  overflow: auto;
}
::v-deep .md-switch:not(.md-disabled) .md-switch-label {
  color: #454242 !important;
}
</style>
